// https://stackoverflow.com/questions/49264426/vuejs-custom-directive-emit-event
// import Vue from 'vue'

const directive = {
    bind(element, binding, vnode) {
        const eventHandler = function(event) {
            let targetValue = event.target.value
            // console.log(`directives targetValue >${ targetValue }<`)
            targetValue = targetValue.trim()
            // console.log(`directives targetValue trim >${ targetValue }<`)

            const isEmptyString = targetValue === ''
            const componentInstance = vnode.componentInstance
            if (isEmptyString && componentInstance) {
                componentInstance.$emit('input', null)
            }
        }

        element.addEventListener('input', eventHandler)

        vnode.context.$once('hook:beforeDestroy', () => {
            element.removeEventListener('input', eventHandler)
        })
    },
}

// export default directive
export default {
    install(Vue) {
        Vue.directive('emptyToNull', directive)
    },
}
