import Vue from 'vue'

function importAllDirectives() {
    console.log('import directives')
    const directives = []

    const directivesContext = require.context(
        '.',
        false,
        /v-[\w-]+\.js$/,
    )

    directivesContext.keys().forEach(fileName => {
        console.log('import directives fileName ///////////////////////////////', fileName)
        const componentConfig = directivesContext(fileName)
        directives.push(componentConfig.default)
    })
    return directives
}

const directives = importAllDirectives()

directives.forEach(directive => {
    console.log('directives directive use', directive)
    Vue.use(directive)
})
