const directive = {
    inserted: function(element, binding, vnode) {
        const tooltipText = binding.value || element.getAttribute('title')
        if (tooltipText) {
            element.removeAttribute('title')
            element.classList.add('tooltip-target')

            const tooltipId = `tooltip-${ Date.now() }`
            element.setAttribute('aria-describedby', tooltipId)

            const tooltip = document.createElement('div')
            tooltip.classList.add('custom-tooltip')
            tooltip.setAttribute('id', tooltipId)
            tooltip.setAttribute('role', 'tooltip')
            tooltip.textContent = tooltipText

            document.body.appendChild(tooltip)

            const updateTooltipPosition = event => {
                const targetBounds = element.getBoundingClientRect()
                tooltip.style.top = `${ event.clientY - tooltip.offsetHeight - 8 }px`
                tooltip.style.left = `${ event.clientX - tooltip.offsetWidth / 2 }px`
            }

            const showTooltip = event => {
                updateTooltipPosition(event)
                tooltip.style.opacity = '1'
                tooltip.style.transform = 'scale(1)'
            }

            const hideTooltip = () => {
                tooltip.style.opacity = '0'
                tooltip.style.transform = 'scale(0.8)'
            }
            element._showTooltip = showTooltip
            element._hideTooltip = hideTooltip
            element._updateTooltipPosition = updateTooltipPosition

            element.addEventListener('mouseenter', showTooltip)
            element.addEventListener('mousemove', updateTooltipPosition)
            element.addEventListener('mouseleave', hideTooltip)
            window.addEventListener('resize', updateTooltipPosition)

            vnode.context.$once('hook:beforeDestroy', () => {
                window.removeEventListener('resize', updateTooltipPosition)
            })
        }
    },
    unbind: function(element) {
        const tooltip = document.querySelector('.custom-tooltip')
        if (tooltip) {
            document.body.removeChild(tooltip)
            element.removeEventListener('mouseenter', element._showTooltip)
            element.removeEventListener('mousemove', element._updateTooltipPosition)
            element.removeEventListener('mouseleave', element._hideTooltip)
        }
    },
}

/*
 const tooltip = {
 inserted: function(element, binding, vnode) {
 const tooltipText = binding.value || element.getAttribute('title')
 if (tooltipText) {
 element.removeAttribute('title')
 element.classList.add('tooltip-target')

 const tooltipId = `tooltip-${ Date.now() }`
 element.setAttribute('aria-describedby', tooltipId)

 const tooltip = document.createElement('div')
 tooltip.classList.add('custom-tooltip')
 tooltip.setAttribute('id', tooltipId)
 tooltip.setAttribute('role', 'tooltip')
 tooltip.textContent = tooltipText

 document.body.appendChild(tooltip)

 const updateTooltipPosition = event => {
 const targetBounds = element.getBoundingClientRect()
 tooltip.style.top = `${ event.clientY - tooltip.offsetHeight - 8 }px`
 tooltip.style.left = `${ event.clientX - tooltip.offsetWidth / 2 }px`
 }

 const showTooltip = event => {
 updateTooltipPosition(event)
 tooltip.style.opacity = '1'
 tooltip.style.transform = 'scale(1)'
 }

 const hideTooltip = () => {
 tooltip.style.opacity = '0'
 tooltip.style.transform = 'scale(0.8)'
 }
 element._showTooltip = showTooltip
 element._hideTooltip = hideTooltip
 element._updateTooltipPosition = updateTooltipPosition

 element.addEventListener('mouseenter', showTooltip)
 element.addEventListener('mousemove', updateTooltipPosition)
 element.addEventListener('mouseleave', hideTooltip)
 window.addEventListener('resize', updateTooltipPosition)

 vnode.context.$once('hook:beforeDestroy', () => {
 window.removeEventListener('resize', updateTooltipPosition)
 })
 }
 },
 unbind: function(element) {
 const tooltip = document.querySelector('.custom-tooltip')
 if (tooltip) {
 document.body.removeChild(tooltip)
 element.removeEventListener('mouseenter', element._showTooltip)
 element.removeEventListener('mousemove', element._updateTooltipPosition)
 element.removeEventListener('mouseleave', element._hideTooltip)
 }
 },
 }
 */

// export default directive
export default {
    install(Vue) {
        Vue.directive('tooltip', directive)
    },
}
