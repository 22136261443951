const directive = {
    bind: (element, binding, vnode) => {
        let isDev = process.env.NODE_ENV === 'development'
        // console.log('dev-only directive isDev', isDev)
        if (!isDev) {
            commentNode(element, vnode)
        }
    },
}
/*
 export const directive = {
 'dev-only': (element, binding, vnode) => {
 let isDev = process.env.NODE_ENV === 'development'
 // console.log('dev-only directive isDev', isDev)
 if (!isDev) {
 commentNode(element, vnode)
 }
 },
 }
 */
// export default directive

/*
 export const directives = {
 'dev-only': (element, binding, vnode) => {
 let isDev = process.env.NODE_ENV === 'development'
 // console.log('dev-only directive isDev', isDev)
 if (!isDev) {
 commentNode(element, vnode)
 }
 },
 }
 export default directives
 */

/*
 Object.keys(directives).forEach(directive => {
 Vue.directive(directive, directives[directive])
 })
 */

/**
 * Create comment node
 *
 * @private
 * @author https://stackoverflow.com/questions/43003976/a-custom-directive-similar-to-v-if-in-vuejs#43543814
 */
function commentNode(element, vnode) {
    const comment = document.createComment(' ')

    Object.defineProperty(comment, 'setAttribute', {
        value: () => undefined,
    })

    vnode.text = ' '
    vnode.elm = comment
    vnode.isComment = true
    vnode.context = undefined
    vnode.tag = undefined
    vnode.data.directives = undefined

    if (vnode.componentInstance) {
        vnode.componentInstance.$el = comment
    }

    if (element.parentNode) {
        element.parentNode.replaceChild(comment, element)
    }
}


export default {
    install(Vue) {
        Vue.directive('devOnly', directive)
    },
}
